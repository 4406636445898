import http from "@/services/http-common";
import qs from "qs";

class ArticleService {
  getAll(page: number, tags?: string[]): Promise<any> {
    return http.get("/articles", {
      params: { page: page, tags: tags },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
  }

  get(slug: string): Promise<any> {
    return http.get(`/articles/${slug}`);
  }

  getRelated(slug: string): Promise<any> {
    return http.get(`/articles/${slug}/related`);
  }
}

export default new ArticleService();
